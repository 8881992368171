import { NextPage } from 'next'
import { useAuthenticationLoading, useIsAuthenticated } from '../wrappers/Auth'
import withConditionalRedirect from './withConditionalRedirect'
import { getToken } from '../lib/authToken'
import jwt from 'jsonwebtoken'

export default function withoutAuthAdmin<P>(
  WrappedComponent: NextPage<P>,
  location = '/admin/dashboard/member',
): NextPage<P> {
  return withConditionalRedirect({
    WrappedComponent,
    location,
    clientCondition: function WithoutAuthClientCondition() {
      return useIsAuthenticated()
    },
    clientAuthenticationLoading: function WithAuthClientAuthenticationLoading() {
      return useAuthenticationLoading()
    },
    serverCondition: function WithoutAuthServerCondition(ctx) {
      return jwt.verify(getToken(ctx), process.env.JWT_SECRET, function (err: any, decoded: any) {
        if (err) {
          return false
        } else {
          return decoded.role.name !== 'agent' || decoded.role.name !== 'admin_agent' ? true : false
        }
      })
    },
  })
}
