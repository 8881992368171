import React from 'react'
import Login from '../../src/components/login'
import withoutAuthAdmin from '../../src/hocs/withoutAuthAdmin'

const Home = () => {
  return (
    <div className="mt-3 mb-3">
      <Login typeAuth={'userBack'} title={'Admin - Management'} />
    </div>
  )
}

export default withoutAuthAdmin(Home)
